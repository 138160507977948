  /***** Ticket *****/


  .ticket-all-wrapper {
      background-color: color(ternary);
      margin-bottom: 2rem;
  }

  .tipster-avatar {
      width: 50px;
      height: 50px;

  }

  .ticket-footer {
      display: flex;
      padding-right: 1rem;
      padding-left: 1rem;
      border-top: 1px solid rgba(256, 256, 256, 0.1);
      justify-content: space-between;
  }

  .ticket-disclamer {
      font-size: 0.75rem;
      font-weight: 600;
      padding: 1rem 0;
  }


  .ticket-img-responsive {
      width: 120px;
      height: auto;
  }

  .ticket-matches {
      border-radius: 0.25rem 0.25rem 0 0;
  }

  @media only screen and (max-width: 500px) {
      .ticket-footer {
          flex-direction: column;
      }
  }


  .close-ticket-x {
      position: absolute;
      padding: 0 1rem;
      top: -0.4rem;
      right: 0;
      background: transparent;
      border: none;
      color: color(info) !important;
      font-size: 2rem;
      z-index: 2;
      cursor: pointer;
  }


  .close-ticket-x:hover,
  .close-ticket-x:active {
      color: #ffffff;
      opacity: .7;
  }

  .close-tooltip {
      white-space: nowrap;
      top: 10px;
      left: -75px;
      background-color: black;
      color: white;
      border-radius: 5px;
      font-size: 0.8rem;
      padding: 0.5rem;
      opacity: 0;
      position: absolute;
      -webkit-transition: opacity 0.5s;
      -moz-transition: opacity 0.5s;
      -ms-transition: opacity 0.5s;
      -o-transition: opacity 0.5s;
      transition: opacity 0.5s;
  }

  .close-ticket-x:hover .close-tooltip {
      opacity: 1;
  }


  .ticket-category-matches {

      padding: 0.4rem 0.8rem;
      font-size: 0.875rem;
      font-weight: 600;
      background-color: color(secondary);
  }