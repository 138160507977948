.download-url {
    word-wrap: normal;
    white-space: nowrap;
    display: block;
    color: color(info);
    width: auto;
    max-height: 100px;
    overflow: auto;
    background-color: transparent;
    border-radius: 3px;
    box-sizing: inherit;
}

.copy-url-button-wrapper {
    position: relative;
}

.copy-url-success {
    display: inline-block;
    margin: 0.5rem auto 1rem auto;
    font-size: 14px;
    padding: 1rem;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 1rem;
    transform: translate(-50%);
    color: #656565;
    background-color: #fff;
    border: 1px solid rgba(101, 101, 101, 0.3);
    border-radius: 6px;
}





// banners

.banner-img {
    width: 100%;
    height: auto;
    margin: 0.2rem 0.1rem 0.2rem 0;
}