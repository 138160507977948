.analyse-avatar {

    width: 1.9rem;
    height: 1.9rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
    margin: 1rem 0.3rem;
}

.bhouse-image {
    margin-top: 0.5rem;
    object-fit: cover;
    object-position: center center;
    width: 6rem;
    height: 3rem;
}

.analysis-bhouse-image {
    float: left;
    margin-top: 0.8rem;
    object-fit: cover;
    object-position: center center;
    width: 4.625rem;
    height: auto;
}

.analyse-card-border {
    // border: 0.1rem solid color(primary);
    border-radius: 0.25rem;
    background-color: color(ternary);
}

.analyse-card-border div {
    // align-items: baseline;
    font-weight: 600;
    word-break: break-word;
}

.analise-data-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0.3rem;
    padding: 1rem 0;
    white-space: nowrap;
}

.analise-data-wrapper-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 0.3rem;
}

.close-analises-x {
    position: absolute;
    padding: 0 2rem;
    top: -0.4rem;
    right: 0;
    background: transparent;
    border: none;
    color: color(info) !important;
    font-size: 2rem;
    z-index: 2;
}

.close-analises-x:hover,
.close-analises-x:active {
    color: #ffffff;
    opacity: .7;
}

.analyse-sport {
    width: 1.2rem;
    height: 1.2rem;
}

.analyse-toggler {
    position: absolute;
    top: 0.25rem;
    right: 1rem;
    padding: 15px;
}

.analyse-span-info {
    color: color(info);
    padding-top: 3px;
    font-size: 0.875rem;
    font-weight: 500;
}

.analyse-span-match {
    color: color(info);
    font-size: 0.875rem;
    padding-top: 3px;
    font-weight: 500;
    white-space: nowrap;
}


.analise-image {
    height: auto;
    max-height: 14rem;
    margin-top: 1rem;
    border-radius: 4px;
}

.analise-image-desktop-visible {
    display: block;
}

.analise-image-desktop-mobile {
    display: none;
}

.analysis-match-date {
    font-size: 0.625rem;
    font-weight: 600;
    opacity: 0.7;
    line-height: 4px;
}


.analysis-match-teams {

 
  
    font-size: 0.9375rem;
    padding-top: 2px;
    font-weight: 600;
    line-height: 20px;
}


.analysis-match-info {
    display: flex;
    justify-content: space-between;
    padding-right: 2rem;
    border-bottom: 1px solid rgba(256, 256, 256, 0.1);
    height: 4rem;
}

.analysis-wrap-tip-stake-odds {
    display: inline-block;
    background-color: color(danger);
    border-radius: 10px;
    color: color(info);
    padding: 0 1rem;
    font-size: 0.75rem;
    margin-right: 0.3rem;
}

.analysis-wrapper-flag {
    min-width: 5.5rem;
    padding-top: 0.5rem;
    text-align: right;
}

.analysis-wrap-tip-stake-odds span {
    margin: 0 0.3rem;
}

.analysis-profile-stats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0.7rem 0.7rem 0.7rem 0.3rem;
    padding-top: 0.3rem;
    white-space: nowrap;
}

.analysis-profile-name {
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 15px;
}

.analysis-profile-yield-profit {
    font-size: 0.625rem;
    padding-top: 0px;
    font-weight: 400;
    line-height: 16px;
}

//left image in analysis that is hidden in media small
.analyse-img-right {
    display: block;
    width: 100%;
    overflow: hidden;
    text-align: right;
}

.analysis-disclamer {
    color: color(info);
    line-height: 0.65rem;
    margin-left: 5.5rem;
    margin-top: 0.75rem;
    opacity: 0.7;
    font-size: 0.625rem;
    font-weight: 700;
    padding: 1rem 0;
}

.analysis-disclamer span {
    white-space: nowrap;
}


@media only screen and (min-width: 1200px) {
    .analysis-match-info {
        display: flex;
        justify-content: space-between;
        padding-right: 0;
    }


}

@media (max-width: 1199.98px) {
    .analyse-img-right {
        display: none;
    }
}

@media (max-width: 575.98px) {

    .analysis-match-info {
        display: flex;
        justify-content: space-between;
        padding-right: 0;
    }




    .analise-image {
        height: auto;
        max-height: 14rem;
        margin-top: 0rem;
        border-radius: 4px;
    }

    .analise-image-desktop-visible {
        display: none;
    }

    .analise-image-desktop-mobile {
        display: block;
    }

}


#update-analysis {
    max-width: 100%;
}


/* override bootstrap dropdown */
.dropdown-menu {
    background-color: #212a38 !important;
    border: none !important;
    color: #fff !important;

}

.dropdown-item {
    background-color: #212a38 !important;
    color: color(info) !important;
    font-weight: 500 !important;
    font-size: 1rem;
    transition: .2s ease-in-out !important;
    padding: 1rem 0.7rem !important;
}

.dropdown-item:nth-child(odd) {
    background-color: #212a38 !important;
    color: color(primary);
}

.dropdown-item:hover {
    background-color: color(ternary) !important;
    color: color(info) !important;
    font-weight: 500 !important;
    font-size: 1rem;
}

.dropdown-item:nth-child(odd):hover {
    color: color(info) !important;
    font-weight: 500 !important;
    font-size: 1rem;
}


/*  analyse card */



.mapped-active-analyses {
    /* display: flex;
    flex-wrap: wrap;
    align-content: flex-start;*/

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}


.analyse-card-wrap {
    // border: 0.1rem solid color(primary);
    position: relative;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: color(ternary);
    width: 320px;
    margin: 0.5rem;
    padding: 0 1.2rem 0 1.2rem;
}

.an-w {
    border: 0.1rem solid #36af47;
}

.an-d {
    border: 0.1rem solid #f7981c;
}

.an-l {
    border: 0.1rem solid #ed1c24;
}

.an-card-header {
    border-bottom: 1px solid #384253;
}

.an-card-avatar {
    min-width: 3.8rem;
    width: 3.8rem;
    height: 3.8rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
    margin: 1rem 0.3rem;
}

.an-card-author {
    font-size: .875rem;
    font-weight: 600;
    line-height: 1rem;
}

.an-card-yieldvalue {
    font-size: .875rem;
    font-weight: 600;
    color: #747f93;
    line-height: 1rem;
}

.an-card-profit {
    font-size: .875rem;
    font-weight: 600;
    color: #747f93;
    line-height: 1rem;
}

.an-card-body {
    color: color(info);
}

.an-card-time {
    padding-top: 2.15rem;
    font-size: 0.675rem;
    opacity: 0.7;
    text-align: center;
}

.card-match-teams {
    white-space: nowrap;
    text-align: center;
    margin-bottom: 1rem;
}


.an-card-tipods {
    font-size: 0.8rem;
    font-weight: 600;
}

.an-card-footer {
    display: flex;
    justify-content: space-between;
}


.an-card-languages {
    position: relative;
    bottom: -1.45rem;
}

.analyse-card-sport {
    min-width: 2rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
    margin: 1.5rem 0.3rem;
}