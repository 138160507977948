.notes-output {
    position: absolute;
    z-index: 3;
    width: 100vw;
    height: 100vh;

}

.transparent-notes-bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(100, 100, 100, 0.3);
}

.main-notes-card {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* box-shadow: 0 4px 8px 0 rgba(233, 59, 112, 0.2);*/
    transition: 0.3s;
    min-width: 300px;
    min-height: 150px;
    background-color: color(secondary);
    /*  border: 0.1rem solid color(danger);*/
    border-radius: 4px;
    z-index: 10;
}

.main-notes-top-card {
    margin: 1rem 0;
    transition: 0.3s;
    min-width: 300px;
    min-height: 150px;
    background-color: color(secondary);
    border: 0.1rem solid color(danger);
    border-radius: 2rem;
    z-index: 10;
}


.main-notes-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.main-notes-container {
    padding: 1rem 2rem 2rem 2rem;
}


.main-notes-form-text {
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: 500;
    padding-bottom: 1rem;
    padding: 0 1rem;
    margin-bottom: 1rem;
    text-align: center;
    line-height: 1.5rem;
}

.main-notes-common {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
}

.main-notes-sucess {
    text-align: center;
    color: color(success);
}

.main-notes-failed {
    text-align: center;
    color: color(error);
}

.close-notes-x {
    position: absolute;
    padding: 0 1rem;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    color: #ffffff;
    font-size: 2rem;
}


.close-notes-x:hover {
    opacity: .7;
}





// confirm-note-output


.confirm-note-output {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
}