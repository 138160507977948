.contact-head {
    font-weight: 600;
    font-size: 2rem;
    line-height: 3.5rem;
    color: color(danger);
    text-align: center;
  }
  
  
  .contact-text {
    font-weight: 400;
    font-size: 0.9rem;
    color: #ffffff;
  }
  
  .text-frame {

    height: auto;
    text-align: center;
  }

  .contact-mail {
    width: 26rem;
    height: auto;
    text-align: center;
    color: color(danger);
    }

    .contact-mail:hover {
        color: #1991eb;
    }

    .contact-mail:focus {
        color: #1991eb;
    }

  
  .body-about-colored-red {
    color: color(danger);
  }
  
  .body-about-colored-blue {
    color: #1991eb;
  }