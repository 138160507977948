@import "Jodit";

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap');
//@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,800,900&display=swap');
@import "Variables";

body {
    background-color: color(secondary) !important;
}

* {
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
}

h1 {
    color: color(info) !important;
    font-size: 1.5rem !important;
    font-weight: 700 !important;

}

h2 {
    color: color(info) !important;
    font-size: 1.2rem !important;
    font-weight: 700 !important;
}

h3 {
    color: color(info) !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    opacity: 0.8;
}


button:focus {
    outline: none !important;
    color: initial;
}




div.jodit_wysiwyg img {
    height: auto !important;
}


.jodit_container .jodit_workplace {
    border: none !important;
    min-height: 3.5rem !important;
}

.jodit_wysiwyg,
.jodit_container {
    min-height: 3.5rem !important;
}

.jodit_container:not(.jodit_inline) {
    background-color: transparent !important; //color(secondary) !important;
}

[data-jodit_iframe_wrapper]:after {
    display: none !important;
}

[data-jodit_iframe_wrapper],
[data-jodit_iframe_wrapper] iframe {
    width: 100% !important;
    max-height: 75vw !important; // so it can be at least 3 : 4 video
    max-width: 600px !important;
    margin: 0 auto !important;
}

.jodit_toolbar,
.jodit_statusbar {
    background: color(secondary);
    border: none;
}

.jodit_statusbar_item span {
    color: white !important;
}

.jodit_icon {
    fill: color(suptile);
}

// remove some jodit buttons
ul.jodit_toolbar li.jodit_toolbar_btn-source,
ul.jodit_toolbar li.jodit_toolbar_btn-font,
ul.jodit_toolbar li.jodit_toolbar_btn-print,
ul.jodit_toolbar li.jodit_toolbar_btn-brush,
ul.jodit_toolbar li.jodit_toolbar_btn-fullsize {
    display: none;
}

.jodit_toolbar_btn-separator {
    border-right: 1px solid color(suptile) !important;
}

ul.jodit_toolbar li.jodit_toolbar_btn :hover {
    background-color: color(innerbox);
}


//style editor

div.jodit_with_dropdownlist-trigger svg {
    fill: color(suptile);
}


.jodit_container .jodit_wysiwyg {
    color: #ffffff;
    background: color(ternary);
}

.jodit_container .jodit_placeholder {
    color: color(suptile);
}




@import "Layout";

@import "NotesOutput";