table,
th,
td {
    border: none;
    color: white !important;
    background-color: color(ternary);
}

table {
    width: 100%;
    display: table;
    border-collapse: collapse;
    border-spacing: 0
}

table.responsive-table {
    font-size: 0.875rem;
}

table.striped tr {
    border-bottom: none
}

table.striped>tbody>tr:nth-child(odd) {
    background-color: color(ternary); //rgba(242, 242, 242, 0.5);
}

table.striped>tbody>tr>td {
    border-radius: 0
}

table.highlight>tbody>tr {
    -webkit-transition: background-color .25s ease;
    transition: background-color .25s ease
}

table.highlight>tbody>tr:hover {
    background-color: color(ternary);
}

table.centered thead tr th,
table.centered tbody tr td {
    text-align: center
}

thead tr {
    border-bottom: 1px solid rgba(256, 256, 256, 0.1);
}

td,
th {
    padding: 15px 15px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px
}




@media only screen and (max-width: 992px) {
    table.responsive-table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        position: relative
    }

    table.responsive-table td:empty:before {
        content: '\00a0'
    }

    table.responsive-table th,
    table.responsive-table td {
        margin: 0;
        vertical-align: top
    }

    table.responsive-table th {
        text-align: left
    }

    table.responsive-table thead {
        display: block;
        float: left
    }

    table.responsive-table thead tr {
        display: block;
        padding: 0 10px 0 0
    }

    table.responsive-table thead tr th::before {
        content: "\00a0"
    }

    table.responsive-table tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap
    }

    table.responsive-table tbody tr {
        display: inline-block;
        vertical-align: top
    }

    table.responsive-table th {
        display: block;
        text-align: right
    }

    table.responsive-table td {
        display: block;
        min-height: 1.25em;
        text-align: left
    }

    table.responsive-table tr {
        border-bottom: none;
        padding: 0 10px
    }

    table.responsive-table thead {
        border: 0;
        border-right: 1px solid color(suptile);
    }
}

table span.badge {
    display: inline-block;
    float: none;
    margin-left: auto
}


td img {
    width: 20px;
    height: 20px;
    border-radius: 25px;
    object-fit: cover;
}

// table-tipster-title
.table-tipster-title {
    display: flex;
    justify-content: center;
    align-items: baseline;
    color: color(info);
    margin: 2rem 0 0.5rem 0;
}

// table-tipster-avatar and name

.table-tipster-avatar-name img {
    margin-right: 0.25rem;
}

.tipster-table-h-name-media {
    text-align: left;
}

// img for chart on tipster stats

.chart-statistics {
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem;
}

.chart-ranking-text {
    display: block;
}

@media only screen and (max-width: 450px) {
    .chart-ranking-text {
        display: none;
    }

    // table-tipster-avatar and name

    .table-tipster-avatar-name {

        position: relative;
        text-align: center;
    }

    .head-t-text {
        padding-bottom: 2rem;
        white-space: nowrap;
    }

    .tipster-table-h-name-media {
        text-align: center !important;
    }

    .table-tipster-avatar-name img {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .table-tipster-avatar-name span {
        text-align: center;
        display: block;
    }

}