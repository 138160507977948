.hamburger-position {
    display: none;
    position: relative;
    margin: 0 1rem 0 1rem;
    
}

.hamburger-box {
    z-index: 2;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.3);
}

.hamburger-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    padding: 0rem;
    cursor: pointer;
}

.hamburger-btn span {
    display: block;
    width: 100%;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    height: 6px;
    background: color(primary);
    transition: all .3s;
    position: relative;
}

.hamburger-btn span+span {
    margin-top: 16px;
}

.hamburger-btn.active span:nth-child(1) {
    animation: ease .7s top forwards;
}

.hamburger-btn.not-active span:nth-child(1) {
    animation: ease .7s top-2 forwards;
}

.hamburger-btn.active span:nth-child(2) {
    animation: ease .7s scaled forwards;
}

.hamburger-btn.not-active span:nth-child(2) {
    animation: ease .7s scaled-2 forwards;
}

.hamburger-btn.active span:nth-child(3) {
    animation: ease .7s bottom forwards;
}

.hamburger-btn.not-active span:nth-child(3) {
    animation: ease .7s bottom-2 forwards;
}

@keyframes top {
    0% {
        top: 0;
        transform: rotate(0);
    }

    50% {
        top: 22px;
        transform: rotate(0);
    }

    100% {
        top: 22px;
        transform: rotate(45deg);
    }
}

@keyframes top-2 {
    0% {
        top: 22px;
        transform: rotate(45deg);
    }

    50% {
        top: 22px;
        transform: rotate(0deg);
    }

    100% {
        top: 0;
        transform: rotate(0deg);
    }
}

@keyframes bottom {
    0% {
        bottom: 0;
        transform: rotate(0);
    }

    50% {
        bottom: 22px;
        transform: rotate(0);
    }

    100% {
        bottom: 22px;
        transform: rotate(135deg);
    }
}

@keyframes bottom-2 {
    0% {
        bottom: 22px;
        transform: rotate(135deg);
    }

    50% {
        bottom: 22px;
        transform: rotate(0);
    }

    100% {
        bottom: 0;
        transform: rotate(0);
    }
}

@keyframes scaled {
    50% {
        transform: scale(0);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes scaled-2 {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}