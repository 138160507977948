/*** wraper for media query blogt ***/

.about-page-media {
 max-width: 992px;
 margin: 0 auto;
}

.active-about-flag {
  border-radius: 50%;
  border: 1px solid color(info);
}
/****** TEXT FORMATING *******/

.lead-red-text {
  font-weight: 600;
  font-size: 2rem;
  line-height: 3.5rem;
  color: color(danger);
}

.lead-white-text {
  font-weight: 600;
  font-size: 2rem;
  line-height: 3.5rem;
  color: #ffffff;
}

.body-about-text {
  font-weight: 400;
  font-size: 1rem;
  color: #ffffff;
}

.body-about-colored {
  color: color(danger);
}

.body-about-colored-blue {
  color: #1991eb;
}

.about-pictures {
  max-width: 45rem;
  width: 100%;
  height: auto;

}

.about-border {
  border-bottom: 0.1rem solid #ffffff;
}

.welcome-caption-text {
  font-weight: 600;
  font-size: 1.4rem;
  color: #1991eb;
}

/****** TEXT FORMATING *******/

/****** Youtube embedded *******/


.iframe-container {
  overflow: hidden;
  position: relative;
  width:100%;
}

.iframe-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/****** Youtube embedded *******/


/************** Mockup *****************/

.mockup-div-center {
  padding-right: 5rem;
  padding-top: 5rem;
}

.about-link {
  font: inherit;
  font-size: 1.2rem;
  color: color(danger);
  font-weight: 500;
  text-decoration: none;
}

/************** Mockup *****************/