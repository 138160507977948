.cursor-pointer {
  cursor: pointer;
}


/***** BLOG POST *****/


/***** BUTTON *****/

.button-blog {
  display: inline-block;
  min-width: 15rem;
  min-height: 3rem;
  text-align: center;
  vertical-align: middle;
  padding: 0.5rem 1rem;
  border: 0rem solid color(danger);
  border-radius: 0.5rem;
  background: color(danger);
  text-shadow: #591717 4px -2px 3px;
  font: inherit;
  font-size: 1rem;
  font-weight: 800;
  color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(233, 59, 112, 0.2), 0 6px 20px 0 rgba(233, 59, 112, 0.19);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  text-decoration: none;
}

.button-blog:hover,
.button-blog:focus {
  background: color(primary);
  color: #ffffff;
  text-decoration: none;
  box-shadow: 0 4px 8px 0 rgba(49, 154, 174, 0.2), 0 6px 20px 0 rgba(49, 154, 174, 0.19);

}



/***** BUTTON *****/


/* button  to look like a href */

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;

}

.link-button:hover,
.link-button:focus {
  text-decoration: none;

}

// right navbar 
.mod-button {
  display: block;
  line-height: 1.5;
  text-align: left;
  user-select: none;
  cursor: pointer;
  padding: .375rem .75rem;
  margin: 0;
  transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out;
  background-color: color(ternary);
  color: color(info) !important;
  font-size: 0.875rem;
  font-weight: 600;
}

.mod-button:nth-child(odd) {
  background-color: #212a38;
}

.mod-button:hover {
  background-color: color(secondary);
}


// arrow and clear buttons for banners 
.clear-banner-btn,
.move-up-btn,
.move-down-btn {
  line-height: 1.5;
  text-align: center;
  user-select: none;
  padding: .375rem .75rem;
  margin: .375rem 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  background-color: #fff;
  border: 0.1rem solid color(primary);
  color: color(primary);
  border-radius: 50%;
  font-size: 0.9rem;
  font-weight: 900;
}


.clear-banner-btn:hover,
.move-up-btn:hover,
.move-down-btn:hover {
  background-color: color(primary);
  border: 0.1rem solid color(primary);
  color: #fff;
}

button.success-button {
  line-height: 1.5;
  text-align: center;
  user-select: none;
  cursor: pointer;
  padding: .450rem .75rem;
  margin: .375rem 0.2rem;
  transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    background-image .15s ease-in-out;
  background-image: color(primgrad);
  border: none;
  color: color(info) !important;
  border-radius: 0.2rem;
  font-size: 0.875rem;
  font-weight: 600;
}

button.success-button:hover {
  background-image: color(primgradHover);
  color: color(info) !important;
  border: none;
}




button.cancel-button {
  line-height: 1.5;
  text-align: center;
  user-select: none;
  cursor: pointer;
  padding: .375rem .75rem;
  margin: .375rem 0.2rem;
  transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out;
  background-color: color(ternary);
  border: 1px solid color(borderColor);
  color: color(info) !important;
  border-radius: 0.2rem;
  font-size: 0.875rem;
  font-weight: 600;
}

button.cancel-button:hover,
button.cancel-button:active,
button.cancel-button:focus {
  background-color: color(secondary) !important;
  color: color(info) !important;
  border: 1px solid color(borderColor) !important;
  box-shadow: none !important;
}


button.inactive-button {
  opacity: 0.5;
  line-height: 1.5;
  text-align: center;
  user-select: none;
  cursor: pointer;
  padding: .450rem .75rem;
  margin: .375rem 0.2rem;
  transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    background-image .15s ease-in-out;
  background-color: color(suptile);
  border: none;
  color: color(info) !important;
  border-radius: 0.2rem;
  font-size: 0.875rem;
  font-weight: 600;
}

button.inactive-button:hover,
button.inactive-button:active,
button.inactive-button:focus {
  background-color: color(suptile) !important;
  color: color(info) !important;

  box-shadow: none !important;
}



.link-success-button {

  color: color(primary);
  transition: color .15s ease-in-out;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 800;
}


.link-success-button:hover {
  color: color(danger);
}

.link-success-button-active {
  color: color(danger);
  transition: color .15s ease-in-out;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 800;
}


.tag-labels {
  line-height: 1.5;
  text-align: center;
  user-select: none;
  cursor: pointer;
  padding: .3rem .75rem;
  margin: .375rem 0.2rem;
  transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out;
  background-color: color(ternary);
  color: color(info) !important;
  border-radius: 0.2rem;
  font-size: 0.75rem;
  font-weight: 600;
  border: none;
}

.tag-labels:hover,
.tag-labels:focus {
  background-color: color(secondary) !important;
  color: color(info) !important;
  box-shadow: none !important;
}



button.error-button {
  line-height: 1.5;
  text-align: center;
  user-select: none;
  cursor: pointer;
  padding: .450rem .75rem;
  margin: .375rem 0.2rem;
  transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    background-image .15s ease-in-out;
  background-image: color(errorgrad);
  border: none;
  color: color(info) !important;
  border-radius: 0.2rem;
  font-size: 0.875rem;
  font-weight: 600;
}

button.error-button:hover {
  background-image: color(errorgradHover);
  color: color(info) !important;
  border: none;
}


@media only screen and (max-width: 900px) {
  .link-success-button {
    display: block;
  }
}