.terms-head {
	text-align: center;
	color: #ffffff;
	font-size: 1.2rem;
}

.terms-title {
	text-align: center;
	color: #ffffff;
	padding: 0.8rem 0 0.3rem 0;
	font-weight: 500;
	letter-spacing: 0.1rem;
}

.terms-body {
	text-align: center;
	color: #ffffff;
	padding: 0; 
}