.add-comment-wrapper {
    margin-top: 1rem;
    display: flex;
    align-items: flex-start;
}

.add-comment-textarea {
    margin: 0 0.5rem !important;
    width: 100%;
    max-width: 100% !important;
}


.comments-wrapper {
    margin: 1rem 0;
    padding: 1rem;
    position: relative;
    //  border-bottom: 1px solid color(info);
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.comments-header {
    display: flex;
    height: 5.3rem;
}

.comments-avatar {
    min-width: 4rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
    margin: 1rem 1.3rem 0 0.3rem;
}

.comments-name {
    margin: 1.8rem 0 0 0;
    font-weight: 600;
    line-height: 1rem;
    color: color(info);
}

.comments-time {
    margin: 0;
    color: color(info);
}

.comments-body {
    margin: 0.3rem 0 0.5rem 0.3rem;
    color: color(info);
    word-break: break-word;
}

.comments-del-btn {
    position: absolute;
    top: 0;
    right: 0;
}