.c-pink {
    color: color(brand) !important;
}

.c-aquablue {
    color: color(primary) !important;
}

.c-darkblue {
    color: color(secondary) !important;
}

.c-whitish {
    color: color(info) !important;
}

  




.bc-pink {
    background-color: color(brand) !important;
}

.bc-aquablue {
    background-color: color(primary) !important;
}

.bc-darkblue {
    background-color: color(secondary) !important;
}

.bc-whitish {
    background-color: color(info) !important;
}

.bc-transparent {
    background-color: transparent !important;
    border: none !important;
}

.bc-ternary {
    background-color: color(ternary) !important;
}

.border-color-pink {
    border-color: color(brand) !important;
}