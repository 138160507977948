/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {

    border-bottom: 1px solid #fff !important;
    -webkit-text-fill-color: color(secondary) !important;
    box-shadow: 0 0 0px 1000px color(secondary) inset !important;
    -webkit-box-shadow: 0 0 0px 1000px color(secondary) inset !important;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

select:-moz-focusring,
select::-moz-focus-inner {
    color: transparent;
    text-shadow: 0 0 0 #fff;
}


div.custom-input-box {
    width: 100%;
    max-width: 400px;
    margin: auto;
}


.custom-input-box .inputBox {
    position: relative;

}

.custom-input-box .inputBox input,
.custom-input-box .inputBox textarea {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid color(info);
    outline: none;
    background: transparent;
    transition: .1s ease-in;
}

.custom-input-box .inputBox input:required,
.custom-input-box .inputBox textarea:required {
    box-shadow: none;
}

.custom-input-box .inputBox label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .1s ease-in;
    transform: scale(1);
}



.custom-input-box .inputBox input:focus~label,
.custom-input-box .inputBox textarea:focus~label {
    opacity: 0.5;
    top: -20px;
    left: 0;
    color: color(primary);
    transform: scale(0.75);
    transform-origin: top left;
}

.custom-input-box .inputBox label.custom-label-up {
    top: -20px;
    left: 0;
    color: color(primary);
    transform: scale(0.75);
    transform-origin: top left;
}

.custom-input-box .inputBox span {
    position: absolute;
    bottom: 10px;
    right: 0;
    font-size: 0.8rem;
}


.custom-input-box .inputBox img.eye-regular {
    width: 1.5rem;
    height: auto;
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(-100%);
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    transition: .1s ease-in;
    cursor: pointer;

}

/* custom classes for custom input  */
.custom-input-sm {
    display: inline;
}


/*  class for date picker input in config/DateAndTime.js*/

@media only screen and (max-width: 600px) {
    .react-datepicker {
        position: absolute !important;
        left: 2rem;
        top: 50% !important;
        transform: translateY(-50%) !important;
    }
}

@media only screen and (max-width: 550px) {
    .react-datepicker {
        position: absolute !important;
        left: 0.8rem;
        transform: translateY(-50%) scale(0.8) !important;
    }
}

@media only screen and (max-width: 500px) {
    .react-datepicker {
        position: absolute !important;
        left: 0;
    }
}





/* input odds */
.upInputStake {
    cursor: pointer;
    position: absolute;
    top: 15%;
    right: 0%;
    transform: translate(-10%, -50%);
    width: 3rem;
    height: 1.55rem;
    padding: 0.3rem 0;
    transition: 0.2s ease-in-out;
}

.downInputStake {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(-10%, -50%) rotate(180deg);
    width: 3rem;
    height: 1.55rem;
    padding: 0.3rem 0;
    transition: 0.2s ease-in-out;

}

.upInputStake:hover,
.upInputStake:active {
    transform: translate(-10%, -50%) scale(1.2);
}

.downInputStake:hover,
.downInputStake:active {
    transform: translate(-10%, -50%) rotate(180deg) scale(1.2);
}


button.datetime-input {
    line-height: 1.5;
    text-align: center;
    user-select: none;
    cursor: pointer;
    padding: .375rem .75rem;
    margin: .375rem 0.2rem;
    transition: color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out;
    background-color: color(ternary);
    border: 1px solid color(borderColor);
    color: color(info) !important;
    border-radius: 0.2rem;
    font-size: 0.875rem;
    font-weight: 600;
}

button.datetime-input :hover,
button.datetime-input :active,
button.datetime-input :focus {
    background-color: color(secondary) !important;
    color: color(info) !important;
    border: 1px solid color(borderColor) !important;
    box-shadow: none !important;
}



/* override datepicker */

#datepicker-wraper2 {
    display: inline-block !important;
}

.react-datepicker__input-container {
    width: auto !important;
}


/* date picker in form materilize styled */

.datepicker-custom-form {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
    cursor: pointer;

}

.datepicker-custom-form label {
    position: absolute;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .1s ease-in;
    top: -20px;
    left: 0;
    color: color(primary);
    transform: scale(0.75);
    transform-origin: top left;
}


/*  select option custom forms in analyses */

.c-label-dropdown {
    position: absolute;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .1s ease-in;
    top: -20px;
    color: color(primary);
    transform: scale(0.75);
    transform-origin: top left;
}

.select-custom-form {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: color(info);
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
    cursor: pointer;
}

.select-custom-form option {
    background-color: transparent !important;
    color: color(secondary) !important;
}