
.text-align-footer {
  text-align: center !important;
  justify-content: center !important;
}


.footer-marketing-links {
  color: #7f8fa4;
}


.footer-color {
  background-color: color(secondary);
}

.footer-text {
  font-size: 0.875rem;
  font-weight: 400;
  color: #ffffff;
}


.footer-img {
  max-width: 7rem;
  max-height: auto;
}

