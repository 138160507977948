@import "TopNavbar";
@import "LeftSidebar";
@import "Gallery";
@import "Spinner";
@import "Buttons";
@import "Input";
@import "Blog";
@import "Colors";
@import "HomeBanner";
@import "Ticket";
@import "Analysis";
@import "Comments";
@import "TermsAndConditions";
@import "TableResponsive";
@import "About";
@import "Footer";
@import "Contact";

.layout-width {
    max-width: 120rem;
    margin: auto;
}

div.reset-row {
    margin-right: 0;
    margin-left: 0;
}

.leftsidebar-media-toggle {
    display: none;
}

.hamburger-menu {
    display: inline-block;
}


.c-background {
    background-repeat: no-repeat;
}

//reset bootstrap 
.btn-group {
    margin-bottom: 1px !important;
}

.mx-3-5 {
    margin-left: 1.2rem !important;
    margin-right: 1.2rem !important;
}

.mt-2-5 {
    margin-top: 0.7rem !important;
}

.form-check-label {
    margin-bottom: 0.5rem !important;
}


// finished with bootstrap


.profile-avatar {
    min-width: 4rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
    margin: 1rem 0.3rem;
}

//home page placeholders



.fake-placeholders div{
    position: relative;
    cursor: pointer;
    margin-bottom: 1rem;
}

.fake-placeholders img {
    width: 100%;
}

.fake-placeholders div h3{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%) scale(1);
    transition: .3s ease-in-out;
    -webkit-transition: .1s ease-in-out;
    font-size: 3rem !important;
   
}

.fake-placeholders div:hover h3{
    transform: translate(-50%,-50%) scale(1.3);
}



@media only screen and (min-width: 992px) {


    .fake-placeholders div h3{
        transform: translate(-50%,-50%) scale(1);
        font-size: 2rem !important;
    }
    


    .leftsidebar-media-toggle {
        display: block;
    }

    .hamburger-menu {
        display: none;
    }

}


#moderator-navbar {
    display: none;
    border: 1px solid color(border);
    border-radius: 0.2rem;
    margin: 3rem 0 1.5rem 0;
}

@media screen and (min-width: 920px) {
    #moderator-navbar {
        display: block;
    }

}


@media only screen and (max-width: 600px) {
    // over write .container-fluid in bootstrap

    div.home-banner-container {
        position: relative;
        padding-left: 0;
        padding-right: 0;
    }

}