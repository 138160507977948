.bg-home-image {
    background-image: url("../assets/heroNincha90.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.span-stats {
    font-size: 1rem;
    float: right;
    font-weight: 600;
}

.stats-border {
    margin-top: 3.2rem;
    margin-bottom: 2rem;
    margin-right: 0.5rem;
    background-image: linear-gradient(to left, color(brand), transparent);
}


.big-text {
    font-weight: 800;
    font-size: 4.5rem;
    line-height: 5rem;
    color: color(brand);
}

.wh-text {
    font-weight: 600;
    font-size: 4.5rem;
    line-height: 5rem;
    color: #ffffff;
}

.blog-text {
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff;
}

.counter-text {
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 0.3rem;
}


.media-counter {
    width: inherit;
}

.caption-text {
    font-size: 1.3rem;
    font-weight: 550;
    color: color(info);
}



.media-query-padding {
    padding-bottom: 0;
}

@media screen and (min-width: 1200px) {
    .media-query-padding {
        padding-bottom: 3rem;
    }

}



@media screen and (max-width: 1400px) {
    .big-text {
        font-weight: 800;
        font-size: 3.5rem;
        line-height: 4rem;

    }

    .wh-text {
        font-weight: 600;
        font-size: 3.5rem;
        line-height: 4rem;

    }
}



@media screen and (max-width: 800px) {

    .big-text {
        font-weight: 650;
        font-size: 2.3rem;
        line-height: 2.8rem;

    }

    .wh-text {
        font-weight: 550;
        font-size: 2.3rem;
        line-height: 2.8rem;

    }


    #one {
        float: left;
        margin-right: 0;
        width: auto;
    }

    #two {
        float: right;
        margin-right: 0;
        width: auto;
    }

    #three {
        float: right;
        margin-right: 0;
        width: auto;
    }

    #four {
        float: right;
        margin-right: 0;
        width: auto;
    }

    #five {
        float: right;
        margin-right: 0;
        width: auto;
    }

    #six {
        float: right;
        margin-right: 0;
        width: auto;
    }
}


/* Statistics */

.circle-wrapper {
    position: relative;
}

.circle-wrapper:hover .outer-circle {
    filter: blur(2px);
    -webkit-filter: blur(2px);
    opacity: 0.3;
    border: 0.5rem solid color(primary);
}

.circle-border-pink {
    border: 0.6rem solid color(brand);
}

.circle-border-whitish {
    border: 0.6rem solid color(info);
}

.circle-wrapper:hover .text-in-circle {
   transform:  translate(-50%, -50%) scale(1.5);
   text-shadow: 2px 4px 4px #000000;
}

.outer-circle {
    width: 6rem;
    height: 6rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    background-color: transparent;
    border: 0.6rem solid color(primary);
    transition: .15s ease-in-out;
}


.text-in-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.7rem;
    font-weight: 600;
    white-space: nowrap;
    transition: .15s ease-in-out;
    text-shadow: 1px 2px 2px #000000;
}

@media screen and (min-width: 410px) {
    .outer-circle {
        width: 7rem;
        height: 7rem;
        border: 0.6rem solid color(primary);
    }

    .text-in-circle {
        font-size: 0.9rem;
        text-shadow: 1px 2px 2px #000000;
    }

    .circle-border-pink {
        border: 0.6rem solid color(brand);
    }
    
    .circle-border-whitish {
        border: 0.6rem solid color(info);
    }
}


@media screen and (min-width: 600px) {
    .outer-circle {
        width: 8.5rem;
        height: 8.5rem;
        border: 1rem solid color(primary);
    }

    .text-in-circle {
        font-size: 1rem;
        text-shadow: 1px 2px 2px #000000;
    }

    .circle-border-pink {
        border: 0.6rem solid color(brand);
    }
    
    .circle-border-whitish {
        border: 0.6rem solid color(info);
    }

}