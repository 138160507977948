$colors: (primary: #2499f2, //#554fac,// //aquablue 
    danger: #e93b70, // pink
    success: #00ff00, //lemon green
    info: #e5e5e5, //whitish
    secondary: #1b2431, // darkblue
    light: white,
    dark: #595959, // not using it yet
    darkest: black,
    ternary: #273142,
    brand: #e02362 , // ,#ff5454
    additional: #7f8fa4,
    suptile: #95979d,
    innerbox: #222c3c,
    primgrad: linear-gradient(#2b9ff6, #1a91eb), //,(#554fac,#6762b7)
    errorgrad: linear-gradient(#dd171e, #f85258),
    errorgradHover: linear-gradient(#f85258, #dd171e),
    primgradHover: linear-gradient(#1a91eb, #2b9ff6), //,( #6762b7, #554fac)
    borderColor: #313d4f,
    error: #dd171e);

@function color($color-name) {
    @return map-get($colors, $color-name)
}