@import "Hamburger";


.top-nav {

    background-color: color(ternary);
    height: 3rem;
    width: 100%;
    max-width: 120rem;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    z-index: 3;
}

.logo-owl {
    font-size: 2.6rem;
    font-weight: 900;
    color: color(brand);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-1.5rem);
    margin-left: 1.5rem;
}

.dropdownWrapper {
    position: relative;
    padding: 1.5rem 2rem;
}

div.dropdownButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: color(primary);
    display: flex;
    align-items: center;
    // padding: 0 1rem;
    margin: 0;
}

div.dropdownButton:hover span {
    color: color(brand);
}

.NabarDropdown {
    display: block;
}

.NavbarDropdownHidden {
    display: none;
}


.NavbarDropdownItems {
    z-index: 3;
    position: absolute;
    right: 0;
    top: 3rem;
    display: flex;
    flex-direction: column;
    background-color: color(secondary);
    white-space: nowrap;
}

.NavbarDropdownItem {
    color: color(primary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0.2rem 0;
    cursor: pointer;
}

.NavbarDropdownItem:nth-child(odd) {
    background-color: #212a38;
    color: color(primary);
}

.NavbarDropdownItem:hover {
    background-color: color(secondary);
    color: color(info);
}

.NavbarDropdownItem:nth-child(odd):hover {
    background-color: #1b2431;
    color: color(info);
}


.NavbarDropdownItemAvatarImg {
    width: 30px;
    height: 30px;
    margin-right: 0.2rem;
    border-radius: 25px;
    border: solid 1px color(additional);
    object-fit: cover;
}

.NavbarDropdownItemLangImg {
    width: 30px;
    height: 30px;
    margin-right: 0.2rem;
    border-radius: 25px;
    border: solid 1px color(additional);
    overflow: hidden;
}

.drop-nav-text {
    font-size: 0.9375rem;
    font-weight: 600;
    color: color(info);
    padding: 0.5rem;
}



.nav-text,
button.nav-text {
    font-size: 0.9375rem;
    font-weight: 600;
    color: color(additional);
    padding: 0.5rem;
}

.nav-text:hover,
button.nav-text:hover,
button.nav-text-register:hover {
    color: color(brand);
}

button.nav-text-register {
    font-size: 1rem;
    font-weight: 800;
    padding: 0.5rem;
    color: color(brand);
}


.NavbarDropdownItem:hover .nav-text {
    color: color(secondary);
}


.nav-text-reg {
    font-size: 1rem;
    font-weight: 800;
    color: color(brand);
}

#dropdownProfileTopNavbar {
    display: block;
}


@media only screen and (max-width: 919px) {

    /*.nav-text,*/
    button.nav-text,
    button.nav-text-register {
        display: none;
    }

    .hamburger-position {
        display: block;
    }

    #dropdownProfileTopNavbar {
        display: none;
    }

}