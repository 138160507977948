.listed-single-blogwrap {
  /*  box-shadow: 0 4px 8px 0 rgba(233, 59, 112, 0.2);*/
  transition: 0.3s;
  min-width: 300px;
  min-height: 150px;
  background-color: color(ternary) !important;
  /*  border: 0.1rem solid color(danger) !important;*/
 // border-radius: 2rem;
}

.image-fit {
  width: 100%;
  height: 300px;
  object-fit: cover;
}