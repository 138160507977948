.left-sidebar-wrapper {
    width: 100%;
  //  height: calc(100% + 3rem);
    min-height: 100vh;
    padding: 4rem 0 2rem 0;
    margin: 0;
    background-color: color(ternary);
 //   background-image: linear-gradient(to right bottom, #001c2b, #102b3b, #213b4c, #324c5e, #435e70);
}


.sidebar-dash {
    width: 90%;
    margin: 0.2rem auto;
    border-bottom: 1px solid color(info);
}



